import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import Marquee from 'react-fast-marquee'
import { ThemeContext } from '../context/ThemeContext'
import Layout from '../components/Layout'
import { H2 } from '../components/Text'
import { Grid, Column } from '../components/Grid'
import Cover from '../components/Cover'
// import Section from '../components/Section'
import Image from '../components/Image'
// import StyledLink from '../components/StyledLink'
// import SplitText from '../components/SplitText'
// import Tile from '../components/Tile'
// import Div from '../components/Div'
import { StyledHero } from '../components/Hero'
import FrontH1 from '../components/FrontH1'
// import {
//   getActiveLangPath,
//   isDefaultLanguage,
// } from '../components/SelectLanguage'
import { breakpoints, fluidRange, breakpointNr } from '../style'
// import { routes } from '../routes'
// import getMetaFromPost from '../lib/getMetaFromPost'

const Root = styled('section')`
  height: calc(100svh - 3.125rem);

  @media ${breakpoints.large} {
    height: calc(100vh - 3.125rem);
  }
`
const HeroGrid = styled('div')`
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: repeat(6, 1fr);
  width: 100%;
  height: 100%;
  row-gap: 0.875rem;
  column-gap: 0.875rem;

  @media ${breakpoints.medium} {
    grid-template-columns: repeat(12, 1fr);
    row-gap: 1.75rem;
    column-gap: 1.75rem;
  }
`

const HeroImageContainer = styled('div')`
  position: relative;
  overflow: hidden;
  display: flex;
  grid-column: 2 / span 5;
  grid-row: 1 / span 1;

  @media ${breakpoints.medium} {
    grid-column: 8 / span 5;
  }
  div {
    height: 100%;
  }
`

const HeroContentContainer = styled('div')`
  grid-column: 1 / span 6;
  grid-row: 1 / span 1;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media ${breakpoints.medium} {
    grid-column: 1 / span 10;
    grid-row: 1 / span 1;
  }
`

const FrontH2 = styled(FrontH1)`
  font-size: 3em;

  @media ${breakpoints.large} {
    font-weight: 400;
    font-size: ${fluidRange({
      min: 100,
      max: 150,
      viewportMin: breakpointNr.large,
      viewportMax: breakpointNr.xlarge,
    })};
  }
`

const ServicesExceprt = styled(FrontH1)`
  font-size: ${fluidRange({
    min: 115.5,
    max: 140,
    viewportMin: breakpointNr.large,
    viewportMax: breakpointNr.xlarge,
  })} !important;
`

export default function Index({ data, location }) {
  const theme = React.useContext(ThemeContext)

  const { page, marqueText, featuredImage } = data.contentfulPage

  const marqueArray = marqueText
    .split('—')
    .map(item => `${item.trim()} —`)
    .slice(0, -1)
  return (
    <Layout
      // meta={getMetaFromPost(data.contentfulPage)}
      location={location}
    >
      <Root>
        <StyledHero pt={1} pb={[3, 0]} style={{ height: '100%' }}>
          <HeroGrid>
            {/* <Column sm={12}> */}
            <HeroContentContainer>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  flex: 1,
                  gap: '32px',
                }}
              >
                <FrontH2 heroTitle={page.title} />
                <ServicesExceprt heroTitle={`—${page.excerpt.excerpt}`} />
              </div>
            </HeroContentContainer>
            <HeroImageContainer>
              <Cover bg={theme.background}>
                {featuredImage && (
                  // <Image fluid={featuredImage.fluid} alt="" />
                  <Image
                    fluid={featuredImage?.fluid}
                    alt=""
                    aspectRatio="auto"
                  />
                )}
              </Cover>
            </HeroImageContainer>
            {/* </Column> */}
          </HeroGrid>
        </StyledHero>
      </Root>

      <Grid gutter="none" mobileGutter="none">
        {marqueText && (
          <Marquee autoFill className="overflow-y-hidden" pauseOnHover>
            {marqueArray.map((item, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Column md={12} pt={10} key={index}>
                <H2 as="h2" mb="0" textColor={theme.color}>
                  {item}
                </H2>
              </Column>
            ))}
          </Marquee>
        )}
      </Grid>
      {/*
      <Section
        id="case-section"
        pt={[3, 6]}
        pb={[8, 16]}
        pr={[0.5, 0]}
        pl={[0.5, 0]}
      >
        <CaseGrid>
          {featuredCases.map(node => (
            <Tile
              key={node.id}
              url={`${getActiveLangPath()}/work/${node.slug}`}
              title={node.client.name}
              image={node.featuredImage}
              video={node?.featuredVideo}
              tags={node.tags}
              bg={node.color}
              mb="0"
              // awards={node.awards}
            />
          ))}
        </CaseGrid>
        <Grid>
          <Column>
            <Div mt={[3, 6]}>
              <StyledLink
                to={`${getActiveLangPath()}/${routes.work.link}`}
                variant="large"
                textColor={theme.color}
                styleVariant={theme.theme}
              >
                <SplitText copy={casesLinkText} role="heading" type="chars" />
                <SplitText copy={casesLinkText} role="heading" type="chars" />
              </StyledLink>
            </Div>
          </Column>
        </Grid>
      </Section> */}
    </Layout>
  )
}

export const pageQuery = graphql`
  query($slug: String!, $locale: String!) {
    contentfulPage: contentfulServices(
      page: { slug: { eq: $slug }, node_locale: { eq: $locale } }
    ) {
      title
      marqueText
      featuredImage {
        fluid(quality: 80, maxWidth: 2000) {
          ...GatsbyContentfulFluid
        }
      }
      servicesList {
        name
        displayText
        description
        link
        icon {
          file {
            contentType
          }
          svg {
            content
            originalContent
            dataURI
            absolutePath
            relativePath
          }
        }
      }
      page {
        title
        slug
        excerpt {
          excerpt
        }
        seoTitle
        seoDescription {
          seoDescription
        }
        seoImage {
          og: resize(width: 1200, height: 630, quality: 80) {
            src
          }
        }
      }
    }
  }
`
